<template>
  <v-container class="page py-8">
    <v-row>
      <v-col cols="12" class="d-flex">
        <h2 class="text-h4">{{ page.name }}</h2>
        <div v-if="isAdmin" class="page_actions d-flex justify-end pl-4 ml-auto">
          <v-btn
            depressed
            fab
            x-small
            class="mr-2"
            :to="{ name: 'EditPage', params: { id: page.id } }"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
            depressed
            fab
            x-small
            @click.prevent.stop="deletePage(page)"
            color="error"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex page_info" v-if="isAdmin">
        <!-- <span class="d-flex align-center text-body2">
          <v-icon size="1.25em" class="mr-1">mdi-calendar</v-icon>
          {{ $dayjs(page.published_at).format('DD MMMM YYYY') }}
        </span> -->
        <span class="d-flex align-center text-body2">
          <!-- <v-icon size="1.25em" class="mr-1">mdi-eye-outline</v-icon> -->
          Просмотры:
          {{ page.views }}
        </span>
      </v-col>
      <v-col cols="12" class="page_content">
        <div v-html="page.content"></div>
      </v-col>
    </v-row>
    <PopupConfirmDeletePage
      v-if="isAdmin"
      v-model="isPopupConfirmDeletePageVisible"
      :page="pageToDelete"
      @deleted="$router.push({ name: 'Home' })"
    />
  </v-container>
</template>

<script>
import PopupConfirmDeletePage from "@/components/PopupConfirmDeletePage.vue";
import { TOGGLE_PAGE_LOADER } from "@/store/app/types";
import { GET_PAGE } from "@/store/pages/types";

export default {
  name: "Page",
  metaInfo() {
    const title = this.page.name;
    return {
      title,
    };
  },
  created() {
    this.$store.dispatch(TOGGLE_PAGE_LOADER, true);

    this.$store.dispatch(GET_PAGE, this.id).then(() => {
      this.$store.dispatch(TOGGLE_PAGE_LOADER, false);
    });
  },
  data: () => ({
    isPopupConfirmDeletePageVisible: false,
    pageToDelete: "",
  }),
  props: ["id"],
  components: {
    PopupConfirmDeletePage,
  },
  computed: {
    page() {
      return this.$store.getters.page;
    },
    pageStatus() {
      return this.$store.getters.pageStatus;
    },
    isAdmin() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    deletePage($event) {
      this.pageToDelete = $event;
      this.isPopupConfirmDeletePageVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  max-width: 900px;
}
.page_info {
  opacity: 0.7;
}
::v-deep {
  .page_content {
    font-size: 1.25rem;

    hr {
      height: 0.125em;
      border: none;
      background: var(--v-subtle-darken1);
      margin: 1em 0;
    }
    a {
      color: var(--v-accent-base);

      * {
        color: inherit !important;
      }
    }
    img {
      max-width: 100%;
      border-radius: 4px;
    }
    .image {
      margin: 0 auto 1em;
      max-width: 100%;

      &-style-block-align-left {
        margin-left: 0;
        margin-right: auto;
      }
      &-style-block-align-right {
        margin-left: auto;
        margin-right: 0;
      }
      &-style-align-left {
        float: left;
        margin-right: 1em;
      }
      &-style-align-right {
        float: right;
        margin-left: 1em;
      }
    }
    .text-small {
      font-size: 0.85em;
    }
    .text-tiny {
      font-size: 0.7em;
    }
    .text-big {
      font-size: 1.4em;
    }
    .text-huge {
      font-size: 1.7em;
    }
    p {
      margin-bottom: 1em;
    }
    ul,
    ol {
      margin-bottom: 1em;
    }
    li {
      margin-top: 0.5em;

      &::marker {
        font-weight: 600;
        color: var(--v-accent-base);
      }
    }
    .table {
      overflow: auto;
      border: 1px solid var(--v-subtle-darken1);
      border-radius: 4px;
      margin: 1em auto;
      width: 100%;

      table {
        border-collapse: collapse;
        min-width: 100%;
      }
      thead {
        tr {
          border: none;
        }
      }
      tr {
        border-top: 1px solid var(--v-subtle-darken1);
      }
      th,
      td {
        padding: 0.5em;

        &:first-child {
          padding-left: 1em;
        }
        &:last-child {
          padding-right: 1em;
        }
      }
      th {
        text-align: left;
        font-weight: 500;
        padding-top: 1em;
        padding-bottom: 1em;
        background-color: var(--v-subtle-base);
      }
    }
    blockquote {
      position: relative;
      border-left: 0.125em solid var(--v-accent-base);
      padding: 1em 2em;
      margin-bottom: 16px;
      font-style: italic;

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -0.25em;
        height: 2em;
        background-color: #fff;
        width: 0.5em;
        margin-top: -1em;
      }
      &::after {
        content: "";
        position: absolute;
        mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNOTYuNCA0MTZoNzcuMWw1MC45LTk2LjZWOTZoLTE2MHYyMjMuNGg3Ny4xTDk2LjQgNDE2em0yMjQgMGg3Ny4xbDUwLTk2LjZWOTZIMjg4LjR2MjIzLjRoODJsLTUwIDk2LjZ6Ii8+PC9zdmc+")
          center / contain;
        background: var(--v-accent-base);
        display: block;
        width: 1em;
        height: 1em;
        top: 50%;
        left: -0.5em;
        margin-top: -0.5em;
      }
    }
  }
}
</style>